<template>
  <main class="my-5 py-5">
    <!-- <main-jumbotron /> -->
    <div class="managers-council about-us my-5">
      <MissionAndVision
        :about_photo="about_photo"
        :heading="heading"
        :paragh-text="paraghText"
      />
    </div>
    <b-container>
      <b-row>
        <b-col v-for="(item, index) in items" :key="index" sm="12" md="6">
          <h2 class="main-heading mt-5" v-html="item.heading"></h2>
          <!-- {{ item.heading }} -->
          <p
            v-if="typeof item.text === 'string'"
            class="about-us__text"
            v-html="item.text"
          >
            <!-- {{ item.text }} -->
          </p>
          <b-navbar-nav v-for="(itemText, key) in item.text" v-else :key="key">
            <b-nav-text class="about-us__text" v-html="itemText"></b-nav-text
            ><!-- {{ itemText }} -->
          </b-navbar-nav>
        </b-col>
        <b-col sm="12">
          <Articles
            class="mt-5"
            :heading="subHeading"
            :paragh-text="subParaghText"
          />
        </b-col>
      </b-row>
    </b-container>
    <ProjectStarter />
  </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AboutUs",
  components: {
    MissionAndVision: () => import("@/components/MissionAndVision"),
    Articles: () => import("@/components/Articles"),
    ProjectStarter: () => import("@/components/Project")
  },
  computed: {
    ...mapGetters(["GlobalSettings", "settings"])
  },
  watch: {
    GlobalSettings: {
      handler() {
        this.paraghText = this.settings("about_description");
        this.subParaghText = this.settings("about_why_solutionplus");
        this.items[0].text = this.settings("about_our_message");
        this.items[1].text = this.settings("about_our_schedule").split("<br/>");
        this.about_photo = this.settings("about_photo");
      },
      immediate: true
    }
  },
  data() {
    return {
      subHeading: this.$t("heading.why_solution_plus"),
      subParaghText: "hos",

      heading: this.$t("heading.about_us"),
      paraghText: "hossssssss",

      items: [
        {
          heading: this.$t("heading.our_message"),
          text: ""
        },
        {
          heading: this.$t("heading.our_schedule"),
          text: []
        }
      ],
      about_photo: ""
    };
  }
};
</script>
